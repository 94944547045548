<template>
  <div class="d-print-none">
    <v-badge
      v-show="done"
      class="fixed-btn fixed-cart-btn"
      overlap
      color="red"
      :content="totalQuantity"
      :value="totalQuantity"
    >
      <v-btn @click="goCartIndex" fab dark class="fixed-cart-btn__vbtn">
        <v-icon>ri-shopping-cart-line</v-icon>
      </v-btn>
    </v-badge>
    <!-- <PrimaryModal :show="requestCooperation.switch">
      <v-card-title class="d-flex justify-center">
        <span class="pb-5">請先與 {{ layoutProviderName }} 建立合作</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <v-btn min-width="100px" color="primary" @click="requestCooperation.switch = false">確認</v-btn>
        </div>
      </v-card-text>
    </PrimaryModal> -->
  </div>
</template>

<script>
// import PrimaryModal from "components/modal/primaryModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // PrimaryModal,
  },
  data: () => ({
    done: false,
    requestCooperation: {
      switch: false,
    },
  }),
  computed: {
    ...mapGetters("member", ["currMemberRole"]),
    isCooperation() {
      return this.$store.getters["store/isCooperation"];
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerId() {
      return this.$route.params.providerId;
    },
    totalQuantity() {
      const total = this.$store.getters["cart/totalQuantity"];
      return total ? total : 0;
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
  },
  created() {
    this.getCart();
  },
  methods: {
    async getCart() {
      this.done = false;
      if (this.currMemberRole && this.currMemberRole._isManager) {
        try {
          const res = await this.$api.collection.cartApi.read(
            this.storeId,
            this.providerId
          );
          this.$store.dispatch(`cart/setCart`, res);
        } catch (err) {
          console.error(err);
        } finally {
          this.done = true;
        }
      }
    },
    goCartIndex() {
      // if (!this.isCooperation) {
      //   this.requestCooperation.switch = true;
      //   return;
      // }
      this.$router.push({
        name: "cart",
        params: { providerId: this.providerId },
      });
    },
  },
};
</script>

<style lang="sass">
.fixed-cart-btn
  &__vbtn
    backdrop-filter: blur(180px)
    background-color: #122a4778 !important
    .v-icon
      font-weight: 200 !important
      font-size: 24px !important
</style>
